* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Open-Sans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecf2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* added font family */
@font-face {
  font-family: "Open-Sans-Regular";
  src: local("Open-San"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype"),
    url(./assets/fonts/OpenSans-Regular.woff) format("woff"),
    url(./assets/fonts/OpenSans-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Open-Sans-Bold";
  src: local("Open-San"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype"),
    url(./assets/fonts/OpenSans-Bold.woff) format("woff"),
    url(./assets/fonts/OpenSans-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Open-Sans-Light";
  src: local("Open-San"),
    url(./assets/fonts/OpenSans-Light.ttf) format("truetype"),
    url(./assets/fonts/OpenSans-Light.woff) format("woff"),
    url(./assets/fonts/OpenSans-Light.woff2) format("woff2");
}
