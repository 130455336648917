/* Harm Sight page container */
.print-page-container {
  padding: 0 2%;
  overflow-y: hidden;
}

/* Harm Sight page 2 top section containing the back button and the title */
.print-page-top-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

/* accessing the back button in the top section */
.print-page-back-btn img {
  width: 150px !important;
}

/* Harm Sight page 2 title */
.print-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-bottom: 20px;
  padding-left: 160px;
  font-size: 50px;
  margin-left: 350px;
}

/* Accessing the print section */
.print-page-print-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

/* Accessing the print title */
.print-page-print-section-title {
  font-family: "Open-Sans-Bold";
  font-size: 40px;
  color: #0470b7;
  margin-bottom: 80px;
}

/* Accessing the print selectors  */
.print-page-data {
  width: 500px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  background-color: #0470b7;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  font-size: 35px;
  margin-bottom: 40px;
  cursor: pointer;
}


/* Accessing the checkbox inside the print page */
.print-page-checkbox {
  width: 30px;
    height: 30px;
    margin-right: 45px;
    margin-left: 20px;
    border-radius: 10px;
    cursor: pointer;
}

/* Accessing the text next to the checkbox */
.print-page-checkbox-label{
  cursor: pointer;
}

/* Accessing the next button */
.print-page-button {
  float: right;
  margin-top: 180px;
}

/* Accessing the next button image */
.print-page-button .btn-container img.medium {
  width: 180px;
}

/* Accessing the next button text */
.print-page-button .btn-text {
  font-size: 24px;
}
