/* start category list style */
.characteristics-group-item {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 45px;
  border-radius: 10px;
}

/* this means that the third element will not has margin right */
.characteristics-group-item:nth-child(3n + 3) {
  margin-right: 0;
}

/* accessing the button then accessing the background image */
.characteristics-group-list-container .btn-container,
.characteristics-group-list-container .btn-container .medium {
  width: 100%;
  height: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* accessing the category title  */
.characteristics-group-list-container .btn-text {
  font-size: 20px;
}

/* the characteristics-group item content container  */
.characteristics-group-content {
  border: 1px solid #737873;
  border-top: none;
  max-height: 490px;
  overflow-y: auto;
  scrollbar-color: #0d7bbe #abdfeb !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/*chrome*/
.characteristics-group-content::-webkit-scrollbar {
  width: 15px;
  background: #abdfeb !important;
}

.characteristics-group-content::-webkit-scrollbar-track {
  border-radius: 5px;
}

.characteristics-group-content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #0d7bbe;
  height: 10px !important;
}

/* accessing the image container */
.characteristics-group-image-container {
  padding: 15px;
}

/* accessing the image itself */
.characteristics-group-image {
  border-radius: 10px;
  width: 100%;
  height: 330px;
}

/* edit and delete buttons section container */
.characteristics-group-manage-buttons {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
}

/* edit and delete buttons container and background image */
.characteristics-group-manage-buttons .btn-container .small,
.characteristics-group-manage-buttons .btn-container {
  width: 130px !important;
}

.characteristics-group-manage-buttons .btn-container img {
  height: 50px;
}

.characteristics-group-feature {
  text-align: center;
  color: #fff;
  font-family: "Open-Sans-Bold";
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 7px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #0d7bbe;
  height: 16px;
}

/* end characteristics group list style */
