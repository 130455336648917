.article-group-popup-main-container.hide {
  display: none;
}

/* article group popup overlay, it's the background of the all popup*/
.article-group-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}

.article-group-popup-overflow-hidden {
  overflow: hidden;
}

/* this is the popup container */
.article-group-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

/* this is the popup title and the close button */
.article-group-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

/* this is the close button */
.article-group-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* close button on hover */
.article-group-popup-close:hover {
  opacity: 0.9;
}

/* the form inside the popup */
.article-group-popup-form-container {
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";

}
/* the fields section */
.article-group-fields-section {
  width: 100%;

}

/* Accessing the input label */
.article-group-popup-input-label {
  color: #fff;
  font-size: 24px;
  font-family: "Open-Sans-Bold";
  width: 100%;
  margin-bottom: 5px;
}

/* used for each input inside the popup */
.article-group-popup-input {
  width: 93%;
  position: relative;
}

.article-group-popup-input .my-input {
  margin-bottom: 25px;
  padding-left: 85px;
}

/* the name field icon */
.article-group-popup-form-container .article-group-icon {
  position: absolute;
  left: 0;
  width: 60px;
  height: 65px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  display: flex;
  align-items: center;
  justify-content: center;
}
.article-group-popup-form-container .article-group-icon img {
  width: 30px;
  height: 50px;
}

/* the validation error message */
.article-group-popup-error-message {
  color: red;
  position: absolute;
  bottom: 2px;
  font-size: 18px;
}


/* The popup submit button */
.article-group-submit-button-container {
  display: flex;
  justify-content: center;
}
