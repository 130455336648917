.my-input {
  height: 62px;
  font-size: 20px;
  width: 100%;
  padding: 0 20px;
  color: #737873;
  color: black;
  margin-bottom: 40px;
  border: 2px solid white;
  border-radius: 10px;
  padding-left: 60px;
  background-color: white;
}

.my-input:focus {
  border: 2px solid #0470b7 !important;
  outline: none !important;
}

::placeholder {
  color: #bec0d6;
}

.my-checkbox-label {
  color: #fff;
  font-size: 30px;
  font-family: "Open-Sans-Bold";
  padding-left: 20px;
}

.my-checkbox {
  height: 30px;
  width: 30px;
}
