/* task sequence page container */
.task-sequence-page-container {
  padding: 0 2%;
}

/* top section is the back button and title and the create new button */
.task-sequence-page-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

/* accessing the back button in the top section */
.task-sequence-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.task-sequence-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 44px;
}

/* accessing the new button container */
.create-new-task-sequence-div .btn-container img {
  width: 370px;
}

/* accessing the new button text */
.create-new-task-sequence-div .btn-text {
  width: 370px;
  font-size: 23px;
  padding-left: 15px;
}

/* The task sequence list part */
.task-sequence-list {
  display: flex;
  flex-wrap: wrap;
}
