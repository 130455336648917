.characteristic-popup-main-container.hide {
  display: none;
}

/* popup overlay */
.characteristic-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}
.characteristic-popup-overflow-hidden {
  overflow: hidden;
}

/* popup container */
.characteristic-popup-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
}

/* this is the title and the close button */
.characteristic-popup-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

/* close button */
.characteristic-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.characteristic-popup-close:hover {
  opacity: 0.9;
}

/* form container */
.characteristic-popup-form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* fields section or inputs */
.characteristic-popup-fields-section {
  width: 65%;
}

/* inputs in the first section wrapper */
.characteristic-popup-inputs-wrapper {
  display: flex;
  flex-direction: column;
}

/* the fields label */
.characteristic-popup-input-label {
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-family: "Open-Sans-Bold";
  margin-bottom: 5px;
  width: 300px;
}

/* fields icons */
.characteristic-popup-form-container .category-icon {
  background: url(assets/icons/category-popup.png) no-repeat;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}
.characteristic-popup-form-container .name-icon {
  background: url(assets/icons/name-icon.png) no-repeat;
  background-color: #0775ba;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}
.characteristic-popup-form-container .article-group-icon {
  background: url(assets/icons/article-group-icon.png) no-repeat;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}
.characteristic-popup-form-container .item-number-icon {
  background: url(assets/icons/item-number-icon.png) no-repeat;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}

/* the wrapper of the inputs */
.characteristic-popup-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 93%;
  position: relative;
}
.characteristic-popup-input .my-input {
  margin-bottom: 20px;
  padding-left: 85px;
}


/* validation error message */
.characteristic-error-message {
  color: red;
  position: absolute;
  bottom: -6px;
  font-size: 18px;
}

/* validation error message */
.characteristic-error-message-image {
  color: red;
  position: absolute;
  bottom: -6px;
  font-size: 18px;
  margin-bottom: 400px;
  margin-right: 50px;
}

/* the select or dropdown design  */
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 15px;
  background-image: url(assets/icons/drop-down-icon.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 3px) center;
  background-size: 40px;
  height: 66px !important;
}

/* the help icon */
.characteristic-popup-help {
  display: flex;
  width: 70px;
  height: 60px;
  background-image: url(assets/images/blue-button.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 405px;
  margin-right: 10px;
}

.characteristic-popup-help.show {
  display: block;
}

.characteristic-popup-help.show.clicked {
  background-image: url(assets/images/selected-button.png);
  background-repeat: no-repeat;
  background-size: 115px;
}

.characteristic-popup-help-image {
  width: 55px;
  height: 49px;
  padding: 5px;
}

.characteristic-popup-help-image-clicked {
  width: 55px;
  height: 49px;
  padding: 5px;
}

/* Image section */
.characteristic-popup-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 35%;
  margin-left: 25px;
}

.characteristic-popup-image,
.characteristic-popup-image.image-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.characteristic-default-picture {
  width: 100%;
}

.characteristic-popup-image-section .btn-container {
  margin-bottom: 30px;
}

.characteristic-popup-image-section img.small {
  width: 200px;
  height: 50px;
}

.characteristic-popup-image-section .btn-text {
  margin-top: 5px;
}

.characteristic-list {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.characteristic-item {
  text-align: center;
  width: 47%;
  height: 30px;
  background-color: white;
  border: none;
  color: #999999;
  font-size: 10px;
  font-family: "Open-Sans-Bold";
  margin-top: 20px;
  margin-right: 15px;
}

.characteristic-item:nth-child(2n + 2) {
  margin-right: 0px;
}
