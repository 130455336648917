.login-back-btn {
  width: 124px;
  position: relative;
  padding: 30px;
}

.login-back-btn .btn-container img {
  height: 68px;
  width: 130px;
}

.login-back-btn .btn-text {
  font-size: 24px;
  text-transform: uppercase;
}

.login-container {
  position: relative;
  max-width: 400px;
  margin: auto;
  padding: 0 20px;
}

.login-container .btn-container img {
  height: 80px;
}

.login-inner {
  position: absolute;
  /* top: -50px; */
  left: 50%;
  transform: translate(-50%, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  width: 100%;
  text-align: center;
}

.login-title {
  color: #737873;
  font-family: "Open-Sans-Bold";
  font-size: 28px;
  text-transform: uppercase;
}

.login-sub-title {
  color: #737873;
  font-family: "Open-Sans-Bold";
  font-size: 18px;
  padding-bottom: 80px;
  border: none;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.username-input,
.password-input {
  padding-right: 60px;
  position: relative;
}

.username-icon {
  background: url(../../assets/icons/username-icon.png) no-repeat;
  background-size: 30px 40px;
  position: absolute;
  left: 10px;
  width: 30px;
  height: 40px;
  z-index: 1;
  top: 10px;
}

.password-icon {
  background: url(../../assets/icons/lock-icon.png) no-repeat;
  background-size: 30px 40px;
  position: absolute;
  left: 10px;
  width: 30px;
  height: 40px;
  z-index: 1;
  top: 10px;
}

.login-error-message {
  position: absolute;
  bottom: 12px;
  color: red;
}
