/* Capture article page container */
.capture-article-page-container {
  padding: 0 2%;
  overflow: hidden;
}

/* Capture article page 2 top section containing the back button and the title */
.capture-article-page-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  /* margin-top: 30px; */
}

/* Capture article page 2 title */
.capture-article-page-title {
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 30px;
}

/* Accessing the section with field which will display the numbers pressed on the num pad  */
.capture-article-page-numpad-number-field {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #999999;
  width: 300px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 24px;
  /* margin-right: 0px; */
}

/* Accessing the # icon in the field */
.capture-article-page-numpad-number-field img {
  width: 60px;
  height: 60px;
}

/* Accessing the capture article content section */
.capture-article-page-content-section {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Accessing the section on the right where we display the characteristics */
.capture-article-page-characteristics-list {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  width: 20%;
}

/* Accessing each characteristic in the characteristics section */
.capture-article-page-characteristic {
  margin-bottom: 10px;
}
/* Accessing the characteristic image container*/
.capture-article-page-characteristic-image {
  width: 120px;
  height: 120px;
  border: 5px solid #999999;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

/* Accessing the characteristic Image */
.capture-article-page-characteristic img {
  width: 100%;
  height: 100%;
}

/* Accessing the Characteristic Name */
.capture-article-page-characteristic-name {
  width: 150px;
  font-family: "Open-Sans-Bold";
  color: #0470b7;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
}

/* Accessing the horizontal line under each characteristic  */
.capture-article-page-characteristic hr {
  height: 20px;
  border: 0px;
  border-left: 3px solid #0470b7;
  height: 30px;
  margin-left: 73px;
}

/* the tasks container */
.capture-article-page-tasks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  /* margin-left: 100px; */
}

/* the tasks container */
.capture-article-tasks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  /* margin-left: 100px; */
}

/* Accessing the tasks section */
.capture-article-tasks-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

/* Task image section */
.capture-article-tasks-section-task-image{
  width: 90px;
}

/* Task image section image */
.capture-article-tasks-section-task-image img{
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.capture-article-tasks-section-task {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.capture-article-task-item-img {
  width: 50px;
  height: 45px;
  cursor: pointer;
}

/* task item name and icon container */
.capture-article-task-item-name-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  background-color: #a5cde1;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
}

/* task item name  */
.capture-article-task-item-name-icon h2 {
  font-size: 38px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: #0470b7;
}

/* task item name container in the open case */
.capture-article-task-item-name-icon-open {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  color: white;
  background-color: #0470b7;
  padding: 15px;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* task item name container in the open case */
.capture-article-task-item-name-icon-open h2 {
  font-size: 38px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: white;
}

.capture-article-task-item-opened-description {
  font-size: 20px;
  padding: 15px;
  background-color: #0470b7;
  border-radius: 5px;
  color: white;
  width: 600px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-bottom: 20px;
}

/* Accessing the slider in the inputs section */
.capture-article-page-inputs-section-input-slider input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.capture-article-page-inputs-section-input-slider label {
  cursor: pointer;
  text-indent: -9999px;
  width: 180px;
  height: 50px;
  display: block;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 5px;
}

.capture-article-page-inputs-section-input-slider label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 80px;
  height: 40px;
  background: #e9f1f5;
  transition: 0.3s;
  border-radius: 5px;
  box-shadow: 2px 2px #999999;
}

.capture-article-page-inputs-section-input-slider input:checked + label {
  background: #0470b7;
}

.capture-article-page-inputs-section-input-slider input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.capture-article-page-inputs-section-input-slider label:active:after {
  width: 130px;
}

/* End the part of accessing the slider */

/* Capture article no task found */
.capture-article-page-no-tasks-found{
  font-size: 38px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: #999999;
}

/* Accessing the section with numpad */
.capture-article-page-numpad-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Section that contains the keys in the numpad */
.capture-article-page-numpad-section-numbers {
  width: 310px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 20px;
  margin-bottom: 40px;
}

/* Accessing the each key in the numpad */
.capture-article-page-numpad-section-numbers-input {
  width: 80px;
  height: 80px;
  background-color: #e9f1f5;
  border: 3px solid white;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 35px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 3px 3px #999999;
}

/* Accessing the back image in the num key */
.capture-article-page-numpad-section-numbers-input img {
  width: 50px;
  height: 50px;
  margin-top: 15px;
}

/* Accessing the third key on each row in the numpad */
.capture-article-page-numpad-section-numbers-input:nth-child(3n + 3) {
  margin-right: 0;
}

/* the key numpad styling on hoover */
.capture-article-page-numpad-section-numbers-input:hover {
  background-color: #0470b7;
  color: #ffffff;
}

/* Accessing the button container */
.capture-article-page-complete-acquisition-button .btn-container img.medium {
  width: 300px;
}
/* Accessing the button text */
.capture-article-page-complete-acquisition-button .btn-text {
  font-size: 22px;
}
