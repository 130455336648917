/* licenses page container */
.license-page-container {
  padding: 0 2%;
}

/* top section is the back button and title and the create new button */
.license-page-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

/* accessing the back button in the top section */
.license-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.license-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 44px;
}

/* accessing the new button container */
.create-new-license-div .btn-container img {
  width: 300px !important;
}

/* accessing the new button text */
.create-new-license-div .btn-text {
  width: 300px !important;
  font-size: 23px;
  padding-left: 15px;
}

/* license item */
.license-list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
}

/* license item title */
.license-item-title {
  width: 70%;
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 30px;
  background-color: #a5cde1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* license item buttons */
.license-item-buttons {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
