.placement-item {
  width: 31.8%;
  margin-right: 2%;
  margin-bottom: 45px;
  border-radius: 10px;
  border: 1px solid #737873;
  border-top: none;
  border-radius: 15px;
}

/* this means that the forth element will not has margin right */
.placement-item:nth-child(3n + 3) {
  margin-right: 0;
}

/* accessing the characteristic title this is not a button*/
.placement-title {
  text-align: center;
  background-color: #124d7e;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  text-transform: uppercase;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-bottom: 30px;
}

.placement-title h1 {
  font-size: 26px;
}

.placement-sub-title {
  margin-bottom: 15px;
  text-align: center;
  background-color: #124d7e;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
}

.placement-sub-title h1 {
  font-size: 24px;
}

.placement-damage-type {
  margin-bottom: 5px;
  text-align: center;
  background-color: #0470b7;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  font-size: 10px;
}

/* the characteristic item content container  */
.placement-item-content {
  max-height: 510px;
  scrollbar-color: #0d7bbe #abdfeb !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* image container */
.placement-image-container {
  /* padding: 15px; */
  min-height: 320px;
}

/* image itself */
.placement-image {
  border-radius: 10px;
  width: 100%;
  height: 380px;
}

/* the name of the print group */
/* .placement-print-group {
  text-align: center;
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
  padding-bottom: 15px;
  padding-top: -5px;
} */

/* edit and delete buttons container */
.placement-buttons-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  padding: 0 20px 10px 20px;
  align-items: center;
}

/* buttons styles */
.placement-buttons-container .btn-container .small,
.placement-buttons-container .btn-container {
  width: 130px !important;
  height: 60px !important;
}
.placement-buttons-container .btn-container .btn-text {
  font-size: 22px;
}

.placement-buttons-container-buttons-container .btn-text {
  font-size: 22px;
  padding-bottom: 5px;
}

/* following category */
.placement-bottom-blue {
  text-align: center;
  color: #fff;
  font-family: "Open-Sans-Bold";
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 7px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #124d7e;
  height: 20px;
}
