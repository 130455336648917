/* tasks page container */
.task-page-container {
  padding: 0 2%;
}

/* top section is the back button and title and the create new button */
.task-page-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

/* accessing the back button in the top section */
.task-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.task-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 44px;
}

/* accessing the new button container */
.create-new-task-div .btn-container img {
  width: 200px;
}

/* accessing the new button text */
.create-new-task-div .btn-text {
  width: 180px;
  font-size: 23px;
}

/* this is the container of the task cards */
.task-list-container {
  display: flex;
  flex-wrap: wrap;
}
