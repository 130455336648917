/* View Damages page container */
.view-damages-page-container {
  padding: 0 2%;
  overflow-y: hidden;
}

/* View Damages page 2 top section containing the back button and the title */
.view-damages-page-top-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

/* accessing the back button in the top section */
.view-damages-page-back-btn img {
  width: 150px !important;
}

/* View Damages page 2 title */
.view-damages-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 50px;
  margin-left: 430px;
}

/* Accessing the View Damages content section */
.view-damages-page-content-section {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

/* Accessing the section on the right where we display the characteristics */
.view-damages-page-characteristics-list {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
}

/* Accessing each characteristic in the characteristics section */
.view-damages-page-characteristic {
  margin-bottom: 10px;
}
/* Accessing the characteristic image container*/
.view-damages-page-characteristic-image {
  width: 120px;
  height: 120px;
  border: 5px solid #999999;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

/* Accessing the characteristic Image */
.view-damages-page-characteristic img {
  width: 100%;
  height: 100%;
}

/* Accessing the Characteristic Name */
.view-damages-page-characteristic-name {
  width: 150px;
  font-family: "Open-Sans-Bold";
  color: #0470b7;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
}

/* Accessing the horizontal line under each characteristic  */
.view-damages-page-characteristic hr {
  height: 20px;
  border: 0px;
  border-left: 3px solid #0470b7;
  height: 30px;
  margin-left: 73px;
}

/* Accessing the task section */
.view-damages-page-task-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}

/* Accessing each task in the task section */
.view-damages-page-task-section-task {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #cce4f0;
  width: 1000px;
  height: 100px;
  margin-left: 150px;
  margin-bottom: 60px;
}

/* Accessing the text in task */
.view-damages-page-task-section-task-h3 {
  font-family: "Open-Sans-Bold";
  color: #0470b7;
  font-size: 35px;
  margin-top: 20px;
  background-color: #cce4f0;
  min-width: 600px;
  padding: 10px;
}

/* Accessing the text in task */
.view-damages-page-task-section-task-hidden {
  margin-top: 20px;
  min-width: 600px;
  padding: 10px;
}

/* Accessing the horizontal line in the task */
.view-damages-page-task-section-task hr {
  border-color: #c0e0f0;
}

.view-damages-placements-damage-types {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* no placement found text */
.view-damages-page-no-placement-found{
  font-family: "Open-Sans-Bold";
  color: #737873;
  text-align: center;
  font-size: 40px;
  margin-left: 500px;
}
