/* Article Group page container */
.article-groups-page-container {
  padding: 0 2%;
}

/* top section is the back button and title and the create new button */
.article-groups-page-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

/* accessing the back button in the top section */
.article-groups-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.article-groups-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 44px;
}

/* accessing the new button container */
.create-new-article-group-div .btn-container img {
  width: 370px;
}

/* accessing the new button text */
.create-new-article-group-div .btn-text {
  width: 370px;
  font-size: 23px;
  padding-left: 15px;
}

/* Article group item */
.article-groups-list-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}

/* Article group item title */
.article-groups-item-title{
    width: 70%;
    font-family: "Open-Sans-Bold";
    color: #737873;
    font-size: 30px;
    background-color: #a5cde1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.article-groups-item-buttons{
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}