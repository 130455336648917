.license-popup-main-container.hide {
  display: none;
}

/* license popup overlay, it's the background of the all popup*/
.license-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}

.license-popup-overflow-hidden {
  overflow: hidden;
}

/* this is the popup container */
.license-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

/* this is the popup title and the close button */
.license-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

/* this is the close button */
.license-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* close button on hover */
.license-popup-close:hover {
  opacity: 0.9;
}

/* the form inside the popup */
.license-popup-form-container {
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
}
/* the fields section */
.license-fields-section {
  width: 100%;
}

/* Accessing the input label */
.license-popup-input-label {
  color: #fff;
  font-size: 24px;
  font-family: "Open-Sans-Bold";
  width: 100%;
  margin-bottom: 5px;
}

/* used for each input inside the popup */
.license-popup-input {
  position: relative;
}

.license-popup-input .my-input {
  margin-bottom: 25px;
  padding-left: 85px;
}


.license-popup-form-container .license-popup-icon {
  position: absolute;
  left: 0;
  width: 60px;
  height: 65px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  display: flex;
  align-items: center;
  justify-content: center;
}
.license-popup-form-container .license-popup-icon img {
  width: 50px;
  height: 50px;
}

/* the validation error message */
.license-popup-error-message {
  color: red;
  position: absolute;
  bottom: 2px;
  font-size: 18px;
}

/* Date picker section */
.license-popup-date-picker-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

/* date picker section items: the From and To */
.license-popup-date-picker-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* date picker icon */
.license-popup-date-picker-section-icon{
  width: 55px;
  height: 55px;
}

/* date picker label */
.license-popup-date-picker-section-label{
  color: #fff;
  font-size: 24px;
  font-family: "Open-Sans-Bold";
}

/* Date picker input */
.react-datepicker-wrapper input{
  width: 300px !important;
  height: 50px;
  border: 0;
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
}

/* date picker input x button */
.react-datepicker__close-icon::after{
  background-color: red !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 20px !important;
}

.license-popup-date-picker-item .react-datepicker__close-icon{
  margin-right: 0;
}

/* The popup submit button */
.license-submit-button-container {
  display: flex;
  justify-content: center;
}
