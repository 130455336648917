.delete-popup-main-container.hide {
  display: none;
}

.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  /* background-color: #ccc; */
  background-color: #f7a8a9;
}

/* .delete-popup-overlay.red {
} */

.delete-popup-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

.delete-popup-top-inner {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.delete-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.delete-popup-close:hover {
  opacity: 0.9;
}

.delete-buttons {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}

.delete-buttons .btn-container .small {
  width: 138px;
}
