/* acquisition page container */
.acquisition-admin-page-main-container {
  padding: 0 2%;
}

/* top section is the back button and title */
.acquisition-admin-page-top-section {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

/* accessing the back button in the top section */
.acquisition-admin-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.acquisition-admin-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 50px;
  margin-left: 280px;
}

/* the table container  */
.acquisition-admin-page-workplacePendingAction-container {
  margin: 50px 0;
}

/* the headers titles */
.acquisition-admin-page-workplacePendingAction-cell-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* the header "Damage #" and Date of capture */
.acquisition-admin-page-workplacePendingAction-item-cell-header {
  background-color: #999999;
  color: white;
  margin: 3px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* each row in the table */
.acquisition-admin-page-workplacePendingAction-item-container {
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

/* the damage number and characteristic names part */
.acquisition-admin-page-workplacePendingAction-item-number-name-section {
  display: flex;
}

/* the characteristic name table cell  */
.acquisition-admin-page-workplacePendingAction-characteristic-names-container {
  display: flex;
  flex-wrap: wrap;
}

/* the damage number table cell  */
.acquisition-admin-page-workplacePendingAction-item-cell {
  background-color: #cce4f0;
  color: #464a46;
  margin: 3px;
  min-width: 180px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* different background color in the table rows */
.background-blue {
  background-color: #8dbed6;
}

/* harm sight and repair buttons container */
.acquisition-admin-page-buttons-container {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

/* accessing the over all button container */
.acquisition-admin-page-buttons-container .btn-container {
  margin-left: 30px;
}

/* accessing the button itself */
.acquisition-admin-page-buttons-container .btn-container .medium {
  height: 100px !important;
}

/* Date picker section */
.acquisition-admin-page-date-picker-section{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

/* date picker section items: the From and To */
.acquisition-admin-page-date-picker-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

/* date picker icon */
.acquisition-admin-page-date-picker-section-icon{
  width: 55px;
  height: 55px;
}

/* date picker label */
.acquisition-admin-page-date-picker-section-label{
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
}

/* Date picker input */
.react-datepicker-wrapper input{
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
}

/* date picker input x button */
.react-datepicker__close-icon::after{
  background-color: red !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 20px !important;
}

.acquisition-admin-page-date-picker-item .react-datepicker__close-icon{
  margin-right: 0;
}

.acquisition-admin-page-date-picker-section-button .btn-container,
.acquisition-admin-page-date-picker-section-button .small{
  width: 300px !important;
}

/* .acquisition-admin-page-date-picker-section-button .small{
  width: 300px !important;
} */

.acquisition-admin-page-date-picker-section-button .btn-text{
  width: 250px !important;
  font-size: 26px;
}