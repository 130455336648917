/* Accessing the Task container */
.task-sequence-card-container {
  width: 31.8%;
  margin-right: 2%;
  margin-bottom: 45px;
  border-radius: 10px;
  border: 1px solid #737873;
  border-top: none;
  border-radius: 15px;
  max-height: 560px;
  overflow-y: auto;
  scrollbar-color: #0d7bbe #abdfeb !important;
}

/*chrome*/
/* the container or background of the scroll bar inside the task sequences item container */
.task-sequence-card-container::-webkit-scrollbar {
  width: 13px;
  background: #abdfeb !important;
  border-bottom-right-radius: 15px;
}

/* the scroll bar itself  */
.task-sequence-card-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #0d7bbe;
  height: 10px !important;
}

/* this means that the third element will not has margin right */
.task-sequence-card-container:nth-child(3n + 3) {
  margin-right: 0;
}

/* The title of card */
.task-sequence-card-title {
  text-align: center;
  background-color: #0470b7;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  text-transform: uppercase;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-bottom: 30px;
}

/* Accessing the container of the tasks list */
.task-sequence-tasks-container {
  height: 360px;
  max-height: 360px;
  text-align: center;
}


/* Accessing each item of the tasks list */
.task-sequence-tasks-items {
  background-color: #0470b7;
  margin-top: 15px;
  height: 40px;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  font-size: 20px;
}

/* edit and delete buttons container */
.task-sequence-buttons-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  align-items: center;
  margin-top: 15px;
}

/* buttons styles */
.task-sequence-buttons-container .btn-container .small,
.task-sequence-buttons-container .btn-container {
  width: 130px !important;
  height: 60px !important;
}
.task-sequence-buttons-container .btn-container .btn-text {
  font-size: 22px;
}

.task-sequence-buttons-container-buttons-container .btn-text {
  font-size: 22px;
  padding-bottom: 5px;
}

/* Accessing the footer part of the task sequence */
.task-sequence-card-footer {
  background-color: #0470b7;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 40px;
}
