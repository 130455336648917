.placement-popup-main-container.hide {
  display: none;
}

/* placement popup overlay, it's the background of the all popup*/
.placement-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}
.placement-overflow-hidden {
  overflow: hidden;
}

/* this is the popup container */
.placement-popup-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 20px;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

/* this is the popup title and the close button */
.placement-popup-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

/* this is the close button */
.placement-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

/* close button on hover */
.placement-popup-close:hover {
  opacity: 0.9;
}

/* the form inside the popup: the fields and the image*/
.placement-popup-form-container {
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  /* display: flex;
  justify-content: space-between; */
}

.placement-popup-input .my-input {
  margin-bottom: 20px;
  padding-left: 85px;
}

/* Input labels */
.placement-popup-input-label {
  font-size: 22px;
  color: white;
  text-transform: uppercase;
}

/* validation error message */
.placement-error-message {
  color: red;
  position: absolute;
  bottom: -7px;
}

.placement-error-message-select {
  color: red;
  position: absolute;
  /* bottom: -7px; */
}

/* image input */
.placement-image-input {
  width: 200px;
}

/* used for each input inside the popup */
.placement-popup-input {
  width: 92.5%;
  position: relative;
}

.my-select {
  width: 108%;
  position: relative;
}

/* the dropdown custom css */
.placement-multi-characteristic-select {
  margin-left: 58px;
  width: 100%;
}

/* start multi select override classes */
.css-1s2u09g-control {
  min-height: 63px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.css-319lph-ValueContainer {
  min-height: 63px;
  height: auto !important;
}
.css-6j8wv5-Input {
  position: relative;
}
.css-g1d714-ValueContainer {
  min-height: 63px;
  height: auto !important;
}
.css-Ido20h {
  /* background-color: red !important; */
  position: absolute;
}
.css-xb97g8 {
  color: red;
}
.css-26l3qy-menu {
  background-color: red;
  color: hsl(0, 0%, 20%);
}

/* end multi select override classes */
.placement-category-icon {
  background: url(assets/icons/category-popup.png) no-repeat;
  background-size: 60px 60px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
}
.placement-article-number-icon {
  background: url(assets/icons/article-number.png) no-repeat;
  background-size: 60px 60px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
}
.placement-characteristics-icon {
  background: url(assets/icons/characteristics-icon.png) no-repeat;
  background-size: 60px 60px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 64px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
}

/* the edit and delete button  */
.placement-popup-container .btn-container {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
}
.placement-popup-container .btn-container .small {
  width: 173px;
  height: 71px;
}
.placement-popup-container .btn-container .btn-text {
  padding-bottom: 6px;
  width: 85%;
  text-transform: uppercase;
}

/* Image section */
.placement-popup-image-section {
  width: 30%;
}

.placement-default-picture {
  width: 100%;
  border-radius: 10px;
}

.placement-popup-submit-button-container {
  display: flex;
  justify-content: center;
}
