.btn-container {
  position: relative;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: auto; */
}

.btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  font-size: 29px;
  color: #fff;
  font-family: "Open-Sans-Bold";
  padding-bottom: 10px;
  align-items: center;
  line-height: 1.1;
}

.admin-sub-menu .btn-text {
  font-size: 17px !important;
}

.btn-container.selected .btn-text {
  color: #2b4d9a;
}

/* start button sizes */
.btn-container img {
  height: 80px;
}

.btn-container .small {
  width: 180px;
  height: 70px;
}

.btn-container .medium {
  width: 220px;
  height: 70px;
}

.btn-container .large {
  width: 420px;
  height: 70px;
}

.btn-container .very-large {
  width: 560px;
  height: 164px;
}

/* end button sizes */
.home-wrapper .btn-container img {
  height: 150px;
}

.btn-container:hover {
  opacity: 0.9;
}

/* start button icons */
.overview-icon {
  background: url(../../assets/icons/overview-icon.png) no-repeat;
  background-size: 40px 50px;
  width: 60px;
  height: 50px;
}

.admin-menu-wrapper .overview-icon {
  background: url(../../assets/icons/menu/overview-icon.png) no-repeat;
  background-size: 40px 50px;
  width: 80px;
  height: 50px;
}

.btn-container.selected .overview-icon {
  background: url(../../assets/icons/menu/overview-selected.png) no-repeat;
}

.print-icon {
  background: url(../../assets/icons/print-receipt.png) no-repeat;
  background-size: 55px 55px;
  width: 80px;
  height: 55px;
}

.login-icon {
  background: url(../../assets/icons/login-icon.png) no-repeat;
  background-size: 43px 55px;
  width: 60px;
  height: 55px;
}

.logout-icon {
  background: url(../../assets/icons/logout-icon.png) no-repeat;
  background-size: 38px 44px;
  width: 45px;
  height: 44px;
}

.btn-container.selected .login-icon {
  background: url(../../assets/icons/open-lock-icon.png) no-repeat;
  background-size: 34px 43px;
  width: 42px;
  height: 44px;
}

.start-icon {
  background: url(../../assets/icons/start-icon.png) no-repeat;
  background-size: 130px 130px;
  width: 140px;
  height: 130px;
  padding-right: 2.1rem;
}

.repair-icon {
  background: url(../../assets/icons/repair-icon.png) no-repeat;
  background-size: 130px 130px;
  width: 140px;
  height: 130px;
}

.btn-container.selected .repair-icon {
  background: url(../../assets/icons/userZone/repairIcon.png) no-repeat;
  background-size: 60px 60px;
  width: 60px;
  height: 60px;
}

.small-repair-icon {
  background: url(../../assets/icons/repair-icon.png) no-repeat;
  background-size: 75px 70px;
  width: 100px;
  height: 65px;
}

.back-icon {
  background: url(../../assets/icons/back-icon.png) no-repeat;
  background-size: 30px 30px;
  width: 37px;
  height: 30px;
}

.damage-icon {
  background: url(../../assets/icons/menu/damage-icon.png) no-repeat;
  background-size: 10px 45px;
  width: 30px;
  height: 50px;
}

.btn-container.selected .damage-icon {
  background: url(../../assets/icons/menu/damage-icon-selected.png) no-repeat;
  background-size: 10px 45px;
}

.good-allocation-icon {
  background: url(../../assets/icons/menu/good-allocation-icon.png) no-repeat;
  background-size: 35px 45px;
  width: 60px;
  height: 49px;
}

.btn-container.selected .good-allocation-icon {
  background: url(../../assets/icons/menu/good-allocation-icon-selected.png)
    no-repeat;
  background-size: 35px 45px;
}

.clean-icon {
  background: url(../../assets/icons/menu/clean-icon.png) no-repeat;
  background-size: 36px 52px;
  width: 60px;
  height: 56px;
}

.btn-container.selected .clean-icon {
  background: url(../../assets/icons/menu/clean-icon-selected.png) no-repeat;
  background-size: 36px 52px;
}

.statistics-icon {
  background: url(../../assets/icons/menu/statistics-icon.png) no-repeat;
  background-size: 40px 38px;
  width: 50px;
  height: 42px;
}

.btn-container.selected .statistics-icon {
  background: url(../../assets/icons/menu/statistics-icon-selected.png)
    no-repeat;
  background-size: 40px 38px;
}

.workplace-icon {
  background: url(../../assets/icons/menu/workplace-icon.png) no-repeat;
  background-size: 42px 42px;
  width: 50px;
  height: 45px;
}

.btn-container.selected .workplace-icon {
  background: url(../../assets/icons/menu/workplace-icon-selected.png) no-repeat;
  background-size: 42px 42px;
}

.category-icon {
  background: url(../../assets/icons/menu/category-icon.png) no-repeat;
  background-size: 35px 35px;
  width: 42px;
  height: 42px;
}

.btn-container.selected .category-icon {
  background: url(../../assets/icons/menu/category-icon-selected.png) no-repeat;
  background-size: 35px 35px;
  width: 42px;
  height: 42px;
}

.product-icon {
  background: url(../../assets/icons/menu/product-icon.png) no-repeat;
  background-size: 42px 45px;
  width: 54px;
  height: 48px;
}

.btn-container.selected .product-icon {
  background: url(../../assets/icons/menu/product-icon-selected.png) no-repeat;
  background-size: 42px 45px;
  width: 54px;
  height: 48px;
}

.edit-icon {
  background: url(../../assets/icons/edit-icon.png) no-repeat;
  background-size: 42px 45px;
  width: 54px;
  height: 48px;
}

.add-icon {
  background: url(../../assets/icons/add-icon.png) no-repeat;
  background-size: 35px 44px;
  width: 42px;
  height: 44px;
}

.no-delete-icon {
  background: url(../../assets/icons/DeletePopup/no-delete-icon.png) no-repeat;
  background-size: 35px 44px;
  width: 42px;
  height: 44px;
}

.yes-delete-icon {
  background: url(../../assets/icons/DeletePopup/yes-delete-icon.png.png)
    no-repeat;
  background-size: 35px 44px;
  width: 42px;
  height: 44px;
}

.delete-icon {
  background: url(../../assets/icons/delete-icon.png) no-repeat;
  background-size: 35px 44px;
  width: 42px;
  height: 44px;
}

.big-delete-icon {
  background: url(../../assets/icons/delete-icon.png) no-repeat;
  background-size: 50px 65px;
  width: 65px;
  height: 65px;
}

.duplicate-order-icon {
  background: url(../../assets/icons/duplicate-icon.png) no-repeat;
  background-size: 35px 50px;
  width: 50px;
  height: 50px;
}

.harm-sight {
  background: url(../../assets/icons/userZone/harm-sight.png) no-repeat;
  background-size: 75px 70px;
  width: 100px;
  height: 65px;
}

.complete-acquisition {
  background: url(../../assets/icons/userZone/complete-acquisition.png);
  background-size: 75px 70px;
  width: 110px;
  height: 65px;
}

.next-icon{
  background: url(../../assets/icons/userZone/next-icon.png) no-repeat;
  background-size: 60px 60px;
  width: 80px;
  height: 55px;
}

/* end button icons */

.view-damages {
  background: url(../../assets/icons/view-damages-icon.png) no-repeat;
  background-size: 75px 70px;
  width: 85px;
  height: 65px;
}