.characteristics-group-page-wrapper {
  padding: 0 30px;
}

/* accessing the back button in the top section */
.characteristics-group-page-top-section {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

/* accessing the back button in the top section */
.characteristics-group-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.characteristics-group-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 44px;
}

/* accessing the new button container */
.create-new-characteristics-group-dev .btn-container img {
  width: 290px;
}

/* accessing the new button text */
.create-new-characteristics-group-dev .btn-text {
  width: 275px;
  font-size: 23px;
}

/* this is the container of the characteristics group cards */
.characteristics-group-list-container {
  display: flex;
  flex-wrap: wrap;
}
