/* start category list style */
.task-item {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 45px;
  border-radius: 10px;
}

/* this means that the third element will not has margin right */
.task-item:nth-child(3n + 3) {
  margin-right: 0;
}

/* accessing the button then accessing the background image */
.task-item-title .btn-container,
.task-item-title .btn-container .medium {
  width: 100%;
  height: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* accessing the task title  */
.task-item-title .btn-text {
  font-size: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* the task item content container  */
.task-item-content {
  border: 1px solid #737873;
  border-top: none;
  overflow-y: auto;
  max-height: 530px;
  scrollbar-color: #0d7bbe #abdfeb !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/*chrome*/
/* the container or background of the scroll bar inside the task item container */
.task-item-content::-webkit-scrollbar {
  width: 13px;
  background: #abdfeb !important;
  border-bottom-right-radius: 15px;
}

/* the scroll bar itself  */
.task-item-content::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #0d7bbe;
  height: 10px !important;
}

/* accessing the image container */
.task-image-container {
  padding: 15px;
}

/* accessing the image itself */
.task-image {
  border-radius: 10px;
  width: 100%;
  height: 330px;
}

.task-damage-type-name {
  margin: 10px 0;
  text-align: center;
  color: #fff;
  font-family: "Open-Sans-Bold";
  font-size: 20px;
  text-transform: uppercase;
  background-color: #0d7bbe;
}

/* edit and delete buttons section container */
.task-item-buttons-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  align-items: center;
}

/* edit and delete buttons container and background image */
.task-item-buttons-container .btn-container,
.task-item-buttons-container .btn-container img {
  width: 160px;
}

/* the first task indicator */
.first-task {
  font-family: "Open-Sans-Bold";
  color: #0d7bbe;
  font-size: 26px;
}

/* list of the task characteristics */
.task-characteristics {
  text-align: center;
  color: #fff;
  font-family: "Open-Sans-Bold";
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 7px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* the characteristic title or name */
.task-characteristics p {
  background-color: #0d7bbe;
  margin-top: 1px;
}
/* end task list style */
