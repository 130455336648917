.landingPage-wrapper {
  display: flex;
  max-width: 1280px;
  margin: auto;
  padding: 200px 0;
  justify-content: space-between;
}

.landingPage-wrapper p {
  color: #ecf2f5;
}

.landingPage-wrapper .btn-text {
  text-transform: uppercase;
  font-size: 55px;
}
