.task-sequence-popup-main-container.hide {
  display: none;
}

/* task sequence popup overlay, it's the background of the all popup*/
.task-sequence-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}

.task-sequence-popup-overflow-hidden {
  overflow: hidden;
}

/* this is the popup container */
.task-sequence-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

/* this is the popup title and the close button */
.task-sequence-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

/* this is the close button */
.task-sequence-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* close button on hover */
.task-sequence-popup-close:hover {
  opacity: 0.9;
}

/* Inputs container */
.task-sequence-popup-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/* the wrapper of the inputs */
.task-sequence-popup-input {
  width: 88%;
  position: relative;
}

/* Accessing the input label */
.task-sequence-popup-input-label {
  color: #fff;
  font-size: 24px;
  font-family: "Open-Sans-Bold";
  width: 100%;
  padding-left: 100px;
  margin-bottom: 5px;
}

/* Accessing the input */
.task-sequence-popup-input .my-input {
  margin-bottom: 25px;
  padding-left: 85px;
  position: relative;
}

/* Accessing the name input */
.task-sequence-popup-input .name-input {
  height: 62px;
  font-size: 20px;
  color: #737873;
  color: black;
  margin-bottom: 25px;
  border: 2px solid white;
  border-radius: 10px;
  background-color: white;
  width: 690px;
  padding-left: 60px;
}

.task-sequence-popup-input .name-input:focus {
  border: 2px solid #0470b7 !important;
  outline: none !important;
}

.task-sequence-popup-input .name-input::placeholder {
  color: #bec0d6;
}

/* Name icon */
.task-sequence-popup-form-container .name-icon {
  background: url(assets/icons/name-icon.png) no-repeat;
  background-color: #0775ba;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}

.task-sequence-popup-multiTask-select {
  margin-left: 58px;
  width: 93%;
  margin-bottom: 25px;
}

/* start multi select override classes */
.css-1s2u09g-control {
  min-height: 63px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.css-319lph-ValueContainer {
  min-height: 63px;
  height: auto !important;
}
.css-6j8wv5-Input {
  position: relative;
}
.css-g1d714-ValueContainer {
  min-height: 63px;
  height: auto !important;
}
.css-Ido20h {
  /* background-color: red !important; */
  position: absolute;
}
.css-xb97g8 {
  color: red;
}
.css-26l3qy-menu {
  background-color: red;
  color: hsl(0, 0%, 20%);
}

/* task-icon */
.task-sequence-popup-form-container .task-icon {
  background: url(assets/icons/overview-icon.png) no-repeat;
  background-color: #0775ba;
  background-size: 55px 60px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}

/* category-icon */
.task-sequence-popup-form-container .category-icon {
  background: url(assets/icons/category-popup.png) no-repeat;
  background-color: #0775ba;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}

/* sequence-chooser-icon */
.task-sequence-popup-form-container .sequence-chooser-icon {
  background: url(assets/icons/task-sequence-popup.png) no-repeat;
  background-color: #0775ba;
  background-size: 60px 60px;
  border-radius: 10px 0 0 10px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
}

/* the validation error message */
.task-sequence-popup-error-message {
  color: red;
  position: absolute;
  bottom: 2px;
  font-size: 18px;
  padding-top: 15px;
}

/* The button container */
.task-sequence-submit-button-container{
  margin-top: 30px
}
