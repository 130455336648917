.admin-menu-wrapper {
  display: flex;
  padding: 10px 30px 0 30px;
  justify-content: space-between;
  /* flex-wrap: wrap; */

  /* to make the menu fixed */
  /* position: fixed;
  background-color: #dbecf4;
  z-index: 2;
  width: 97.3%;
  top: 6.7rem; */
}

.admin-menu-wrapper .btn-container .medium {
  /* height: 73px; */
  min-width: 180px;
  max-width: 200px;
  /* width: 90%; */
  margin-bottom: 5px;
  position: relative;
  z-index: 2;
}

.admin-menu-wrapper .login-icon,
.btn-container.selected .login-icon {
  background-size: 30px 40px;
  width: 46px;
  height: 40px;
}

.admin-menu-wrapper .overview-icon,
.btn-container.selected .overview-icon {
  background-size: 32px 44px;
  width: 40px;
  height: 45px;
}

.admin-menu-wrapper .btn-text {
  font-size: 20px;
  z-index: 2;
}

.admin-menu-wrapper .back-btn {
  padding: 12px 0 0 0;
}

.menu-item {
  height: 75px;
}

.admin-menu-wrapper .btn-container {
  z-index: 1;
  position: relative;
}
