.company-popup-main-container.hide {
  display: none;
}

/* company popup overlay, it's the background of the all popup*/
.company-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}

.company-popup-overflow-hidden {
  overflow: hidden;
}

/* this is the popup container */
.company-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

/* this is the popup title and the close button */
.company-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

/* this is the close button */
.company-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* close button on hover */
.company-popup-close:hover {
  opacity: 0.9;
}

/* the form inside the popup*/
.company-popup-form-container {
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  display: flex;
  justify-content: space-between;
}
/* the fields section */
.company-fields-section {
  width: 45%;
  margin-right: 5%;
}

/* Accessing the second Field Child */
.company-fields-section:nth-child(2n+2){
  margin-right: 3%;
}

/* Accessing the input label */
.company-popup-input-label {
  color: #fff;
  font-size: 24px;
  font-family: "Open-Sans-Bold";
  width: 100%;
  margin-bottom: 5px;
}

/* used for each input inside the popup */
.company-popup-input {
  width: 88%;
  position: relative;
  margin-bottom: 20px;
}

.company-popup-input .my-input {
  margin-bottom: 25px;
  padding-left: 85px;
}

/* the input field icon */
.company-popup-form-container .company-icon {
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-popup-form-container .company-icon img {
  width: 45px;
  height: 45px;
}

/* the validation error message */
.company-popup-error-message {
  color: red;
  position: absolute;
  bottom: 2px;
  font-size: 18px;
}

/* The popup submit button */
.company-submit-button-container {
  display: flex;
  justify-content: center;
}
