/* Statistic page container */
.statistic-page-container {
  padding: 0 2%;
}

/* top section is the back button and title*/
.statistic-page-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

/* accessing the back button in the top section */
.statistic-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.statistic-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 44px;
  margin-right: 800px;
}

/* Accessing the content section */
.statistic-page-content-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* Chart section */
.statistic-page-chart-section{
    width: 65%;
}

/* Inputs Section */
.statistic-page-inputs-section{
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Date picker section */
.statistic-page-date-picker-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
}

/* date picker section items: the From and To */
.statistic-page-date-picker-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

/* date picker icon */
.statistic-page-date-picker-section-icon{
  width: 55px;
  height: 55px;
  margin-right: 10px;
}

/* date picker label */
.statistic-page-date-picker-section-label{
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
  width: 200px;
}

/* Date picker input */
.react-datepicker-wrapper input{
  width: 300px !important;
  height: 50px;
  border: 0;
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
}
/* date picker input x button container*/
.react-datepicker__close-icon{
  margin-right: 80px;
}

/* date picker input x button */
.react-datepicker__close-icon::after{
  background-color: red !important;
  width: 25px !important;
  height: 25px !important;
  font-size: 20px !important;
}

/* show statistic button container */
.statistic-page-date-picker-section-button{
  margin-right: 65px;
}

.statistic-page-date-picker-section-button .btn-container,
.statistic-page-date-picker-section-button .small{
  width: 300px !important;
}

.statistic-page-date-picker-section-button .btn-text{
  width: 250px !important;
  font-size: 26px;
}

/* Export section : select and export button */
.statistic-page-export-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

/* select input section */
.statistic-page-export-section-input{
  width: 100%;
}

/* Data choice label */
.statistic-page-export-section-label{
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
}

/* Data choice select input */
.statistic-page-export-input{
  width: 88%;
  position: relative;
}

/* select input icon */
.statistic-page-export-section-category-icon{
  background: url(assets/icons/category-popup.png) no-repeat;
  background-size: 60px 60px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 66px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
}

/* export button section */
.statistic-page-export-section-button{
  margin-right: 65px;
}

.statistic-page-export-section .btn-container,
.statistic-page-export-section .small{
  width: 300px !important;
}

.statistic-page-export-section .btn-text{
  width: 250px !important;
  font-size: 26px;
  margin-left: 20px;
}


