/* Capture article page container */
.select-characteristic-page-container {
  padding: 0 2%;
}

/* Capture article page top section containing the back button and the title */
.select-characteristic-page-top-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  /* margin-top: 30px; */
}

/* Capture article page title */
.select-characteristic-page-title {
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 30px;
  margin-left: 330px;
}

/* breadcrumbs section */
.select-characteristic-page-breadcrumbs-section{
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}

.select-characteristic-page-breadcrumbs-item{
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 24px;
  margin-right: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

/* characteristics card section */
.select-characteristic-page-characteristics-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #0d7bbe #abdfeb !important;
}

/* The characteristic item it self */
.select-characteristic-page-characteristic-item {
  width: 370px;
  min-width: 370px;
  height: 350px;
  border: 5px solid #0470b7;
  border-radius: 15px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

/* The first characteristic item */
.select-characteristic-page-characteristic-item:first-child {
  margin-left: 0;
}

/* the characteristic item button and button image */
.select-characteristic-page-characteristic-item-name .btn-container,
.select-characteristic-page-characteristic-item-name .btn-container .medium {
  width: 377px;
  height: 40px;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* the characteristic button text */
.select-characteristic-page-characteristic-item-name .btn-text {
  font-size: 24px;
  margin-top: 5px;
  width: 95%;
  display: flex;
  justify-content: center;
}

/* The characteristic item image container */
.select-characteristic-page-characteristic-item-image {
  margin: 10px;
  margin-top: 18px;
}

/* The characteristic item image */
.select-characteristic-page-characteristic-item-image img {
  width: 100%;
  height: 280px;
}

/* The characteristic item name  */
.select-characteristic-page-characteristic-item-name {
  width: 377px;
  margin-top: -4px;
}

/* past orders section */
.select-characteristic-page-orders-section {
  margin-top: 30px;
}

/* past orders title */
.select-characteristic-page-orders-section-title {
  width: 250px;
  height: 30px;
  background-color: #999999;
  text-align: center;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
}

/* the container of past orders */
.select-characteristic-page-orders-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 10px;
}

/* list of pass orders */
.select-characteristic-page-orders-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* each past order name */
.select-characteristic-page-orders-items {
  width: 188px;
  background-color: #bbdff0;
  font-family: "Open-Sans-Bold";
  color: #999999;
  font-size: 25px;
  margin-left: 10px;
  text-align: center;
  line-height: 45px;
}

/* first past order of each list */
.select-characteristic-page-orders-items:first-child {
  margin-left: 0;
}

/* duplicate order button */
.select-characteristic-page-orders-button .btn-container .small {
  width: 230px;
  height: 50px;
}

/* duplicate order button text */
.select-characteristic-page-orders-button .btn-text {
  font-size: 18px;
  margin-top: 5px;
}
