/* Repair page container */
.repair-page-container {
  padding: 0 2%;
  overflow-y: hidden;
}

/*Repair page 2 top section containing the back button and the title */
.repair-page-top-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  /* margin-top: 30px; */
}

/* accessing the back button in the top section */
.repair-page-back-btn img {
  width: 150px !important;
}

/*Repair page 2 title */
.repair-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  /* padding-left: 160px; */
  padding-bottom: 20px;
  font-size: 50px;
  /* margin-left: 430px; */
  margin-left: 32%;
}

/*Repair page 2 title */
.repair-page-sub-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  /* padding-left: 110px; */
  /* padding-bottom: 20px; */
  font-size: 35px;
  /* margin-left: 430px; */
  margin-left: 32%;
}

/* all page content container */
.repair-page-content-container {
  display: flex;
  margin-top: 10px;
}

.repair-page-characteristics-column {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
  width: 17%;
  margin-top: -50px;
}

/* Accessing each characteristic in the characteristics section */
.repair-page-characteristic {
  margin-bottom: 10px;
  text-align: center;
}

/* Accessing the characteristic image container*/
.repair-page-characteristic-image {
  width: 150px;
  height: 120px;
  border: 5px solid #999999;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

/* Accessing the characteristic Image */
.repair-page-characteristic img {
  width: 100%;
  height: 100%;
}

/* Accessing the horizontal line under each characteristic  */
.repair-page-characteristic hr {
  height: 20px;
  border: 0px;
  border-left: 3px solid #0470b7;
  height: 30px;
  margin-left: 85px;
}

/* Accessing the Characteristic Name */
.repair-page-characteristic-name {
  width: 175px;
  font-family: "Open-Sans-Bold";
  color: #0470b7;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
}

/* the tasks container */
.repair-page-tasks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  /* margin-left: 100px; */
}

/* the placement and damage type title container */
.repair-page-sub-title {
  display: flex;
}

/* the placement and damage type title */
.repair-page-sub-title h2 {
  font-size: 44px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: #0470b7;
  margin-bottom: 30px;
}

/* Accessing the tasks section */
.repair-page-one-task {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.repair-page-task-item-img {
  width: 50px;
  height: 45px;
  cursor: pointer;
}

/* task item name and icon container */
.repair-page-task-item-name-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  background-color: #a5cde1;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* task item name  */
.repair-page-task-item-name-icon h2 {
  font-size: 38px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: #0470b7;
}

/* task item name container in the open case */
.repair-page-task-item-name-icon-open {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  color: white;
  background-color: #0470b7;
  padding: 15px;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* task item name container in the open case */
.repair-page-task-item-name-icon-open h2 {
  font-size: 38px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: white;
}

.repair-page-task-item-opened-description {
  font-size: 20px;
  padding: 15px;
  background-color: #0470b7;
  border-radius: 5px;
  color: white;
  width: 500px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-bottom: 20px;
}

/* NO task found */
.repair-page-no-task-found{
  font-size: 38px;
  font-weight: bold;
  font-family: "Open-Sans-Bold";
  color: #737873;
  width: 100%;
  text-align: center;
}

.repair-page-submit-button-container,
.repair-page-submit-button-container-next {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 30px;
  align-items: flex-end;
  min-height: 68vh;
}

.repair-page-submit-button-container .btn-container {
  width: 300px;
}

.repair-page-submit-button-container .btn-container .medium {
  width: 300px;
}
