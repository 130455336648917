.characteristic-card-item {
  width: 23.5%;
  margin-right: 2%;
  margin-bottom: 45px;
  border-radius: 10px;
}

/* this means that the forth element will not has margin right */
.characteristic-card-item:nth-child(4n + 4) {
  margin-right: 0;
}

/* accessing the characteristic title this is not a button*/
.characteristic-card-title {
  text-align: center;
  background-color: #0470b7;
  font-family: "Open-Sans-Bold";
  color: #ffffff;
  text-transform: uppercase;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* the characteristic item content container  */
.characteristic-item-content {
  border: 1px solid #737873;
  border-top: none;
  max-height: 510px;
  scrollbar-color: #0d7bbe #abdfeb !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* overflow-y: auto; */
}

/* image container */
.characteristic-card-image-container {
  padding: 15px;
}

/* image itself */
.characteristic-image {
  border-radius: 10px;
  width: 100%;
  height: 310px;
}

/* the name of the print group */
.characteristic-card-print-group {
  text-align: center;
  font-family: "Open-Sans-Bold";
  color: #737873;
  font-size: 20px;
  padding-bottom: 15px;
  padding-top: -5px;
}

/* edit and delete buttons container */
.characteristic-card-buttons-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  align-items: center;
}

/* buttons styles */
.characteristic-card-buttons-container .btn-container .small,
.characteristic-card-buttons-container .btn-container {
  width: 130px !important;
  height: 60px !important;
}

.characteristic-card-buttons-container .btn-text {
  font-size: 22px;
  padding-bottom: 5px;
}

/* following category */
.characteristic-card-following-category {
  width: 100%;
  height: 35px;
  text-align: center;
  background-color: #0470b7;
  font-family: "Open-Sans-Bold";
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
