.damage-type-popup-main-container.hide {
  display: none;
}

/* damage type popup overlay, it's the background of the all popup*/
.damage-type-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -moz-opacity: 0.6;
  z-index: 1;
  background-color: #ccc;
}

.damage-type-popup-overflow-hidden {
  overflow: hidden;
}

/* this is the popup container */
.damage-type-container {
  background-color: #a8acac;
  border-radius: 30px;
  padding: 30px;
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

/* this is the popup title and the close button */
.damage-type-top-section {
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

/* this is the close button */
.damage-type-popup-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* close button on hover */
.damage-type-popup-close:hover {
  opacity: 0.9;
}

/* the form inside the popup: the fields and the image*/
.damage-type-popup-form-container {
  color: #fff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  display: flex;
  justify-content: space-between;
}
/* the fields section */
.damage-type-fields-section {
  width: 65%;
  margin-right: 5%;
}

/* used for each input inside the popup */
.damage-type-popup-input {
  width: 88%;
  position: relative;
}

.damage-type-popup-input .my-input {
  margin-bottom: 25px;
  padding-left: 85px;
}

/* the name field icon */
.damage-type-popup-form-container .damage-type-icon {
  position: absolute;
  left: 0;
  width: 60px;
  height: 65px;
  z-index: 1;
  top: 0;
  border-radius: 10px 0 0 10px;
  background-color: #0775ba;
  display: flex;
  align-items: center;
  justify-content: center;
}
.damage-type-popup-form-container .damage-type-icon img {
  width: 22px;
  height: 54px;
}

/* the radio buttons field */
.damage-type-popup-repair-radio-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* The radio button */
.damage-type-popup-repair-radio-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* The radio button label */
.damage-type-popup-repair-radio-button .my-checkbox-label {
  font-family: "Open-Sans-Bold";
  color: #fff;
  font-size: 25px;
}

/* the validation error message */
.damage-type-popup-error-message {
  color: red;
  position: absolute;
  bottom: 2px;
  font-size: 18px;
}

/* image error message */
.damage-type-popup-error-message-image {
  color: red;
  position: absolute;
  bottom: 2px;
  font-size: 18px;
  margin-bottom: 75px;
}

/* the image section at the right */
.damage-type-popup-image-section {
  width: 30%;
}

/* the image it self */
.damage-type-popup-image {
  width: 100%;
  height: 80%;
  border-radius: 10px;
}

/* The popup submit button */
.damage-popup-submit-button-container {
  display: flex;
  justify-content: center;
}
