/* start damage type list style */
.damage-type-item {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 45px;
  border-radius: 10px;
  text-align: center;
}

/* this means that the third element will not has margin right */
.damage-type-item:nth-child(3n + 3) {
  margin-right: 0;
}

/* Accessing the damage type item title */
.damage-type-item-title {
  width: 100%;
  height: 50px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
    background-color: #0d7bbe;
    color: #ffffff;
  font-size: 22px;
  font-family: "Open-Sans-Bold";
  display: flex;
  align-items: center;
  justify-content: center;
}
/* card content */
.damage-type-card-content {
  border: 1px solid #737873;
  border-top: none;
  height: 480px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* Accessing The image container */
.damage-type-item-image-container {
  padding: 15px;
}

/* Accessing the image */
.damage-type-image {
  border-radius: 10px;
  width: 100%;
  height: 300px;
}

/* Accessing the intern repair */
.damage-type-intern-repair {
    width: 100%;
  height: 40px;
    background-color: #0d7bbe;
    color: #ffffff;
  font-size: 20px;
  font-family: "Open-Sans-Bold";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

/* edit and delete buttons section container */
.damage-type-item-buttons-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  align-items: center;
}

/* edit and delete buttons container and background image */
.damage-type-item-buttons-container .btn-container,
.damage-type-item-buttons-container .btn-container img {
  width: 160px;
}
