/* repair page container */
.repair-overview-page-main-container {
  padding: 0 2%;
}

/* top section is the back button and title */
.repair-overview-page-top-section {
  display: flex;
  align-items: center;
  /* padding-top: 30px; */
  padding-bottom: 10px;
}

/* accessing the back button in the top section */
.repair-overview-page-back-btn img {
  width: 150px !important;
}

/* accessing the page title */
.repair-overview-page-title {
  font-family: "Open-Sans-Bold";
  color: #737873;
  padding-left: 160px;
  padding-bottom: 20px;
  font-size: 50px;
  margin-left: 430px;
}

/* the table container  */
.repair-overview-page-workplacePendingAction-container {
  margin: 50px 0;
}

/* the header "Damage #" */
.repair-overview-page-workplacePendingAction-item-cell-header {
  background-color: #999999;
  color: white;
  margin: 3px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* each row in the table */
.repair-overview-page-workplacePendingAction-item-container {
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

/* the damage number and characteristic names part */
.repair-overview-page-workplacePendingAction-item-number-name-section {
  display: flex;
}

/* the characteristic name table cell  */
.repair-overview-page-workplacePendingAction-characteristic-names-container {
  display: flex;
  flex-wrap: wrap;
}

/* the damage number table cell  */
.repair-overview-page-workplacePendingAction-item-cell {
  background-color: #cce4f0;
  color: #464a46;
  margin: 3px;
  min-width: 220px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* different background color in the table rows */
.background-blue {
  background-color: #8dbed6;
}

/* harm sight and repair buttons container */
.repair-overview-page-buttons-container {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

/* accessing the over all button container */
.repair-overview-page-buttons-container .btn-container {
  margin-left: 30px;
}

/* accessing the button itself */
.repair-overview-page-buttons-container .btn-container .medium {
  height: 100px !important;
}
