.header-container {
  background-color: #999999;
  border-bottom: 8px solid #0470b7;
  padding: 10px 30px;

  /* to make the header fixed */
  /* position: fixed;
  top: 0;
  z-index: 2;
  width: 97.3%; */
}

.header-wrapper {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo-container {
  cursor: pointer;
}

.site-title {
  color: #fff;
  font-family: "Open-Sans-Light";
  font-size: 30px;
}

.header-buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Accessing the repair icon in the repair button */
.header-buttons-container .repair-icon{
  background-size: 38px 44px;
  width: 45px;
  height: 44px;
}

.header-buttons-container button {
  margin-left: 2.5rem;
}

.welcome-container {
  display: flex;
  /* padding-top: 12px; */
}

.welcome-icon {
  width: 65px;
  height: 55px;
}

.welcome-title {
  font-size: 22px;
  max-width: 185px;
  color: #fff;
  font-family: "Open-Sans-Bold";
}

/* .header-wrapper .btn-text {
  padding-bottom: 10px;
} */
